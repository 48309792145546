@import './node_modules/@sbt-suite/components/theme/variables.scss';
@import './node_modules/@sbt-suite/components/theme/globalClasses.scss';

:root {
    --primary-color: #8cd2ff;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;

    * {
        box-sizing: border-box;
    }
}
.history-panel {
    td > span {
        white-space: break-spaces;
    }
}
.custom-data-table {
    .data-table {
        max-height: calc(100vh - 137px) !important;
    }
}

.cdk-column-statusLabel{
    min-width: 165px;
}